import { CSSProperties, MouseEventHandler, ReactNode } from "react";
import { Button } from "react-bootstrap";

export enum CommonButtonType {
    fill,
    outline,
    outlineNoBorder,
    smallOutline,
    extraSmallFill,
    extraSmallInline,
    danger,
    dangerOutline,
    success,
    googleIconFill,
    googleIconOutline,
    googleIconOutlineLarge,
    googleIconNoBorder,
    googleIconNoBorderLarge,
    paginationActive,
    paginationNotActive,
    link,
    smallLink,
    bigMainAction,
    bigMainActionLeft,
    bigMainActionRight,
    fillDropdownLeft,
    fillDropdownRight,
}

export type CommonButtonProps = {
    type: CommonButtonType,
    disabled?: boolean | undefined,
    handler?: MouseEventHandler<HTMLButtonElement> | undefined,
    children?: ReactNode | undefined,
}

const variantFromType = (type: CommonButtonType): string | undefined => {
    switch (type) {
        case CommonButtonType.fill: return "dark"
        case CommonButtonType.outline: return "outline-dark"
        case CommonButtonType.outlineNoBorder: return "outline-dark"
        case CommonButtonType.smallOutline: return "outline-dark"
        case CommonButtonType.extraSmallFill: return "dark"
        case CommonButtonType.extraSmallInline: return "light"
        case CommonButtonType.danger: return "danger"
        case CommonButtonType.dangerOutline: return "outline-danger"
        case CommonButtonType.success: return "success"
        case CommonButtonType.googleIconFill: return "dark"
        case CommonButtonType.googleIconOutline: return "outline-dark"
        case CommonButtonType.googleIconOutlineLarge: return "outline-dark"
        case CommonButtonType.googleIconNoBorder: return "outline-dark"
        case CommonButtonType.googleIconNoBorderLarge: return "outline-dark"
        case CommonButtonType.paginationActive: return "dark"
        case CommonButtonType.paginationNotActive: return "light"
        case CommonButtonType.link: return "link"
        case CommonButtonType.smallLink: return "link"
        case CommonButtonType.bigMainAction: return "dark"
        case CommonButtonType.bigMainActionLeft: return "dark"
        case CommonButtonType.bigMainActionRight: return "dark"
        case CommonButtonType.fillDropdownLeft: return "dark"
        case CommonButtonType.fillDropdownRight: return "dark"
    }
}

const sizeFromType = (type: CommonButtonType): "sm" | "lg" | undefined => {
    switch (type) {
        case CommonButtonType.smallOutline: return "sm"
        case CommonButtonType.extraSmallFill: return "sm"
        case CommonButtonType.extraSmallInline: return "sm"
        case CommonButtonType.smallLink: return "sm"
        case CommonButtonType.googleIconOutlineLarge: return "lg"
        case CommonButtonType.googleIconNoBorderLarge: return "lg"
        default: return undefined
    }
}

const styleFromType = (type: CommonButtonType): CSSProperties | undefined => {
    switch (type) {
        case CommonButtonType.extraSmallFill: return {
            padding: "0.125rem 0.5rem", 
            fontSize: "0.75rem"
        }
        case CommonButtonType.extraSmallInline: return {
            padding: "0.125rem 0.5rem", 
            fontSize: "0.75rem",
        }
        case CommonButtonType.googleIconNoBorder: return {
            "border": "0",
        }
        case CommonButtonType.googleIconNoBorderLarge: return {
            "border": "0",
        }
        case CommonButtonType.outlineNoBorder: return {
            "border": "0",
        }
        case CommonButtonType.bigMainAction: return {
            fontSize: "2rem",
            fontWeight: "500",
            borderRadius: "40px",
            padding: "10px 30px",
        }
        case CommonButtonType.bigMainActionLeft: return {
            fontSize: "2rem",
            fontWeight: "500",
            borderRadius: "40px 0px 0px 40px",
            padding: "10px 10px 10px 30px",
        }
        case CommonButtonType.bigMainActionRight: return {
            fontSize: "2rem",
            fontWeight: "500",
            borderRadius: "0px 40px 40px 0px",
            padding: "10px 20px 10px 5px",
        }
        case CommonButtonType.fillDropdownLeft: return {
            borderRadius: "6px 0px 0px 6px",
            padding: "6px 6px 6px 12px",
        }
        case CommonButtonType.fillDropdownRight: return {
            borderRadius: "0px 6px 6px 0px",
            padding: "6px 12px 6px 6px",
        }
        default: return undefined
    }
}

const classNameFromType = (type: CommonButtonType): string | undefined => {
    switch (type) {
        case CommonButtonType.googleIconFill: return "googleIconButton"
        case CommonButtonType.googleIconOutline: return "googleIconButton"
        case CommonButtonType.googleIconOutlineLarge: return "googleIconButton"
        case CommonButtonType.googleIconNoBorder: return "googleIconButton"
        case CommonButtonType.googleIconNoBorderLarge: return "googleIconButton"
        default: return undefined
    }
}

export const CommonButton: React.FC<CommonButtonProps> = ({
    type,
    disabled,
    handler,
    children,
}) => {
    return <Button
        type="button"
        className={classNameFromType(type)}
        variant={variantFromType(type)}
        size={sizeFromType(type)}
        disabled={disabled}
        onClick={(e) => {
            handler?.(e)
        }}
        style={styleFromType(type)}
    >
        {children}
    </Button>
}