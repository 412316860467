import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { RouterProvider, createBrowserRouter, useRouteError } from 'react-router-dom';
import { WWWPageRegistry } from './components/PageStack/PageURL/WWWPageRegistry';
import { RootPageView } from './components/PageStack/Pages/RootPageView';
import { TermsAndConditionsPageView } from './components/PageStack/Pages/TermsAndConditionsPageView';
import { PrivacyPolicyPageView } from './components/PageStack/Pages/PrivacyPolicyPageView';
import { InstagramPageView } from './components/PageStack/Pages/InstagramPageView';
import { RouterPageHelper } from './_common-client/PageStack/RouterPageHelper';

function ErrorPage() {
    const error: any = useRouteError();
    console.error(error);

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}

const rootPage = WWWPageRegistry.welcome;

const router = createBrowserRouter([
    {
        path: RouterPageHelper.bindRootPath(rootPage),
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <RootPageView />,
            },
            {
                path: RouterPageHelper.bindPath(rootPage, WWWPageRegistry.termsAndConditions),
                element: <TermsAndConditionsPageView />,
            },
            {
                path: RouterPageHelper.bindPath(rootPage, WWWPageRegistry.privacyPolicy),
                element: <PrivacyPolicyPageView />,
            },
            {
                path: RouterPageHelper.bindPath(rootPage, WWWPageRegistry.instagram),
                element: <InstagramPageView />,
            }
        ]
    },
])

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
