import { makeChildPage } from "../../../_common-client/PageStack/PageRegistryBuilder"
import { Page, PageNoParams } from "../../../_common-client/PageStack/PageURL"

// --- Root ---
const rootURL = "/"
const root: Page<PageNoParams, PageNoParams> = {
    routerParams: {},
    baseURL: () => { return { url: rootURL } },
    url: () => { return { url: rootURL } },
}

// --- Terms and Conditions ---
const termsAndConditions = makeChildPage({
    parent: root, 
    component: "terms-and-conditions",
})

// --- Privacy Policy
const privacyPolicy = makeChildPage({
    parent: root,
    component: "privacy-policy",
})

// --- Instagram
const instagram = makeChildPage({
    parent: root,
    component: "instagram",
})

// --- Registry ---
export const WWWPageRegistry = {
    welcome: root,
    termsAndConditions,
    privacyPolicy,
    instagram,
}