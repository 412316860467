
export const PrivacyPolicyPageView: React.FC<{}> = (_) => {
    return <div>
        <p/>
        <h2><b>Privacy Policy</b></h2>
        <p />
        <h4>Introduction</h4>
        <p>Protecting your private information is our priority. This Statement of Privacy applies to the Site and Inomova and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Inomova include the Site and Inomova. The Inomova website is a language learning site. By using the Inomova website, you consent to the data practices described in this statement.
        </p>
        <h4>Collection of your Personal Information</h4>
        <p>If you purchase Inomova's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction. Inomova encourages you to review the privacy statements of websites you choose to link to from Inomova so that you can understand how those websites collect, use and share your information. Inomova is not responsible for the privacy statements or other content on websites outside of the Inomova website.
        </p>
        <h4>Use of your Personal Information</h4>
        <p>Inomova collects and uses your personal information to operate its website(s) and deliver the services you have requested. Inomova may also use your personally identifiable information to inform you of other products or services available from Inomova. Inomova may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered. Inomova does not sell, rent or lease its customer lists to third parties. Inomova may share data with trusted parties to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Inomova, and they are required to maintain the confidentiality of your information. Inomova will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Inomova; (b) protect and defend the rights or property of Inomova; and, (c) act under exigent circumstances to protect the personal safety of users of Inomova, or the public.
        </p>
        <h4>Automatically Collected Information</h4>
        <p>Information about your computer hardware and software may be automatically collected by Inomova. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Inomova website.
        </p>
        <h4>What are Cookies?</h4>
        <p>A cookie is a small text file that is placed on your hard disk by a web page server to uniquely identify your browser or to store information in your browser. Cookies cannot be used to run programs or deliver viruses to your computer. A “web beacon” links web pages to servers and may be used to transmit information collected through cookies back to a web server. Most cookies expire after a certain period of time depending on what they are used for.
        </p>
        <p>Inomova uses both first-party cookies (which are set by our website when it is being visited) and third-party cookies (which are set by a server located outside the domain of our website). If you would like to learn more about this practice and to know your choices about not having this information collected and shared with third parties, visit http://networkadvertising.org/ or review our "Managing your Cookies" section further below.
        </p>
        <h4>Types of Cookies we use</h4>
        <ul>
        <li><h5>Essential Cookies</h5></li>
        <p>Certain cookies are necessary in order for Inomova's website to operate properly. For example, we use cookies to authenticate you. When you log on to our platform, authentication cookies are set which let us know who you are during a browsing session. These cookies also facilitate the social media login functionality (e.g., via Google, GitHub, Facebook, or LinkedIn) on our website. Essential cookies do not include advertising cookies, which are discussed further below.
        </p>
        <li><h5>Personalization/Customization Cookies</h5></li>
        <p>In some cases, we use cookies to remember the choices you make while browsing the Inomova website and to store your preferences for you. When you return to the same Inomova website, the information you previously provided can be retrieved, so you can easily use the Inomova features that you customized. This functionality improves user experience and allows us to customize your site experience accordingly.
        </p>
        <li><h5>Analytics Cookies</h5></li>
        <p>Through our service providers, we may use analytics to collect information about your use of the Inomova website to create reports and statistics on the performance of the website. Analytics collect information such as your IP address, type of device, operating system, geolocation, referring URLs, time and date of page visits, and which pages you visit. The information allows us to quantify our audience size, see the overall patterns of usage on the platform, help us record any difficulties you have with the website, and show us whether our advertising is effective or not.
        </p>
        <li><h5>Advertising Cookies</h5></li>
        <p>We may use advertising cookies to market Inomova products or services to you on third-party websites. For example, if you visit the Inomova website, you may then see an advertisement for Inomova products or services on certain third-party websites you visit in the future. We sometimes use cookies delivered by third parties to track the performance of our advertisements. For example, these cookies remember which browsers have visited our websites. The information provided to third parties does not include personal information. This process helps us manage and track the effectiveness of our marketing efforts.
        </p>
        </ul>
        <h4>Managing your Cookies</h4>
        <p>You have the ability to accept or decline cookies. Most desktop web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. You'll want to refer to your browser's help section to do this since the instructions are usually browser-specific. Mobile devices typically allow you to control cookies through their system settings. Refer to your device manufacturer’s instructions for more information on how to do this. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Inomova services.
        </p>
        <h4>Security of your Personal Information</h4>
        <p>Inomova secures your personal information from unauthorized access, use, or disclosure. Inomova uses the SSL Protocol for this purpose: When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption - the Secure Sockets Layer (SSL) protocol.
        </p>
        <h4>Children under Eighteen</h4>
        <p>Inomova does not knowingly collect personally identifiable information from children under the age of eighteen.
        </p>
        <h4>Changes to this Statement</h4>
        <p>Inomova will occasionally update this Statement of Privacy to reflect company and customer feedback. Inomova encourages you to periodically review this Statement to be informed of how Inomova is protecting your information.
        </p>
        <h4>Contact Information</h4>
        <p>Inomova welcomes your questions or comments regarding this Statement of Privacy. If you believe that Inomova has not adhered to this Statement, please contact Inomova’s team by emailing support@inomova.com
        </p>
    </div>
}