import { Button, Container, Navbar } from 'react-bootstrap';
import { Localized } from './Localized';
import { Outlet } from 'react-router-dom';
import { WWWPageRegistry } from './components/PageStack/PageURL/WWWPageRegistry';
import { usePageNavigation } from './_common-client/PageStack/PageNavigation';
import { CommonButton, CommonButtonType } from './_common-client/UI/CommonButton';
import { LoginLink } from './components/PageStack/PageURL/LoginLink';

const App = () => {
    const navigation = usePageNavigation()

    return <>
        <Navbar bg="light" variant="light" sticky="top">
            <Container>
                <Navbar.Brand
                    style={{ "cursor": "pointer" }}
                    onClick={() => { navigation.toPage(WWWPageRegistry.welcome) }}
                >
                    <div style={{ display: "inline-flex", alignItems: "center", gap: "4px" }} >
                        <div> <b>{Localized.common.appName}</b></div>
                    </div>

                </Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">
                    <CommonButton type={CommonButtonType.fill} handler={() => { navigation.toExternalDirectURL(LoginLink) }} >
                        Continue with Google
                    </CommonButton>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <Container id="pageContainer">
            <Outlet />
        </Container>
        <div style={{ width: "100%", fontSize: "10px", textAlign: "center" }}>
            <Button
                size="sm"
                variant="link"
                style={{ fontSize: "10px", color: "#333333", textDecoration: "none", cursor: "auto" }}
            >© inomova</Button>
            |
            <Button
                as="a"
                size="sm"
                variant="link"
                href={navigation.getURL(WWWPageRegistry.termsAndConditions)}
                style={{fontSize: "10px", color: "#333333", textDecoration: "none"}}
            >Terms of Use</Button>
            |
            <Button
                as="a"
                size="sm"
                variant="link"
                href={navigation.getURL(WWWPageRegistry.privacyPolicy)}
                style={{fontSize: "10px", color: "#333333", textDecoration: "none"}}
            >Privacy Policy</Button>
        </div>
    </>
}

export default App;
