import { Button } from "react-bootstrap"

export const InstagramPageView: React.FC<{}> = (_) => {
    return <div style={{flexGrow: 1, display:"flex", alignItems: "center"}}>
        <div>
            <p />
            <span style={{ fontSize: "2.5em" }}>
                <b>Hi 👋</b><br />
            </span>
            <br />

            <span style={{ fontSize: "1.5em" }}>
                <b>The Instagram's internal browser does not support Google OAuth.</b><br />
                Because of this, you cannot log in and practice here.<br />
                Please open this page in your normal browser and press "Continue".<br />
                Thanks!<br/>
                <br />
            </span>
            
            <Button as="a" href="/" variant="dark">
                Continue
            </Button>
        </div>
    </div>
}