const stringToInt = (value: string | undefined, defaultResult: number): number => {
    if (value === undefined) {
        return defaultResult
    }
    const n = parseInt(value, 10)
    return !isNaN(n) ? n : defaultResult
}

const ENV = {
    CLIENT_ADDRESS: process.env.REACT_APP_CLIENT_ADDRESS ?? "",
    BACKEND_ADDRESS: process.env.REACT_APP_BACKEND_ADDRESS ?? "",
    REQUEST_DELAY: stringToInt(process.env.REACT_APP_REQUEST_DELAY, 0),
};

export default ENV;