import { Page } from "./PageURL"

const buildBindPath = <T>(page: Page<T, any>): string => {
    return page.baseURL(page.routerParams).url
}

const bindPath = <P, T>(parent: Page<P, any>, child: Page<T, any>): string => {
    const parentPath = buildBindPath(parent)
    const childPath = buildBindPath(child)
    if (childPath.startsWith(parentPath)) {
        return childPath.substring(parentPath.length)
    }
    return childPath
}

export const RouterPageHelper = {
    bindRootPath: buildBindPath,
    bindPath,
}