import { matchPath } from "react-router-dom";
import { Page, PageNoParams } from "./PageURL";
import { isNull } from "../../_common/TypeGuards";

type ChildPage<P> = {
    parent: Page<P, any>;
    component: string;
}

export const makeChildPage = <P>(args: ChildPage<P>): Page<P, PageNoParams> => {
    return makeChildPageWithParams<P, P>({
        parent: args.parent, 
        routerPathParams: args.parent.routerParams,
        component: () => args.component,
    })
}

type ChildPageWithParams<C extends P, P> = {
    routerPathParams: C;
    parent: Page<P, any>;
    component: (params: C) => string;
}

export const makeChildPageWithParams = <C extends P, P>(args: ChildPageWithParams<C, P>): Page<C, PageNoParams> => {
    const urlBuilder = (params: C) => ({ url: `${args.parent.baseURL(params).url}${args.component(params)}/` })
    return {
        routerParams: args.routerPathParams,
        baseURL: urlBuilder,
        url: urlBuilder,
    }
}

type ChildPageWithSearchParams<C extends P, P, S> = {
    routerPathParams: C;
    parent: Page<P, any>;
    component: (params: C) => string;
    generateSearchParams: (params: S) => Record<string, string | null>;
}

const addSearchParams = (base: string, searchParams: Record<string, string | null>): string => {
    const urlSearchParams = new URLSearchParams()
    Object.keys(searchParams).forEach((key) => {
        const value = searchParams[key]
        if (!isNull(value)) {
            urlSearchParams.append(key, value)
        }
    })
    const strSearchParams = urlSearchParams.toString()
    if (strSearchParams !== "") {
        return base + "?" + urlSearchParams
    }
    return base
}

export const makeChildPageWithSearchParams = <C extends P, P, S>(args: ChildPageWithSearchParams<C, P, S>): Page<C, S> => {
    return {
        routerParams: args.routerPathParams,
        baseURL: (params: C) => ({ url: `${args.parent.baseURL(params).url}${args.component(params)}/` }),
        url: (params: C & S) => ({ url: addSearchParams(`${args.parent.baseURL(params).url}${args.component(params)}/`, args.generateSearchParams(params)) }),
    }
}

export const PageRegistryHelper = {
    match: <T>(pathname: string, page: Page<T, any>): boolean => {
        return matchPath(page.baseURL(page.routerParams).url, pathname) !== null
    },
}